<template>
  <div>
续租延期
  </div>
</template>
<script>
export default {
  data () {
    return {

    }
  },
  mounted () {

  },
  computed: {

  },
  methods: {

  }
}
</script>
<style scoped>

</style>
